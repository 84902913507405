(function () {
  $(document).on("turbolinks:load", function () {
    if (!!$("#js-table-of-contents").length) {
      const contentsList = document.getElementById("js-table-of-contents"); // 目次を追加する先(table of contents)
      const div = document.createElement("div"); // 作成する目次のコンテナ要素
      const matches = document.querySelectorAll(".article__title");

      // 取得した見出しタグ要素の数だけ以下の操作を繰り返す
      matches.forEach(function (value, i) {
        // 見出しタグ要素のidを取得し空の場合は内容をidにする
        let id = value.id;
        if (id === "") {
          id = value.textContent;
          value.id = id;
        }
        const ul = document.createElement("ul");
        const li = document.createElement("li");
        const a = document.createElement("a");

        // MEMO: 管理画面(?)で適切な目次表示にならない場合があるためchildNodesから参照するようにした
        // a.innerHTML = value.innerText;
        a.innerHTML = value.childNodes[0].wholeText;
        a.href = "#" + value.id;
        // 追加する<ul><li><a>タイトル</a></li></ul>を準備する
        li.appendChild(a);
        ul.appendChild(li);

        try {
          switch (value.tagName) {
            case "H2":
              div.appendChild(ul);
              break;
            case "H3":
              div.lastElementChild.lastElementChild.appendChild(ul);
              break;
            case "H4":
              div.lastElementChild.lastElementChild.lastElementChild.appendChild(
                ul,
              );
              break;
            default:
              break;
          }
        } catch {
          if ( window.location.pathname.includes( 'admin' ) ) {
            const targetContent = `${ value.childNodes[ 0 ].wholeText.trim() }`
            alert( `⚠️目次が表示されません⚠️ 前後の見出しサイズが間違っている可能性があります. 次の見出し付近の文書構造を確認してください. \n${ targetContent }` )
          }
        }
      });
      // 最後に画面にレンダリング
      contentsList.appendChild(div);

    }

    const h2 = document.querySelector('.l-detail__content > h2')

    // const endDay = new Date(2023, 7, 12);
    // const _today = new Date();
    // const today = new Date(_today.getFullYear(), _today.getMonth(), _today.getDate(), 0, 0, 0);
    // const termDay = (endDay - today) / 86400000;
    // console.log(termDay)

    if (h2) {
      const questionnaireHTML = `
      <div class="p-promotion">
        <div class="p-promotion__header">
          <span class="p-promotion__header__notification">Pikawakaからのお得なお知らせ</span>
          <p class="p-promotion__header__text">LINEの友だち追加で、Twitterで約2000リツイートされた「エンジニア職種13選」を無料でプレゼント！</p>
        </div>
        <div class="p-promotion__body">
          <p class="p-promotion__body__first-line">
        LINEの
            <a href="https://lin.ee/8DRGhCs" rel="nofollow opener" target="_blank" class="js-h2_line_text_first">Pikawaka公式アカウント</a>
        に友達追加した方限定で、先日Twitterでバズった「エンジニア職種13選」の資料を無料プレゼントさせて頂きます。
          </p>
          <img alt="エンジニア職種一覧の画像" rel="nofollow opener" class="p-promotion__body__image" src="https://programan.s3.amazonaws.com/uploads/content_image/h2_joblist_5_1100px.png">
          <div class="p-promotion__body__qr">
            <p class="p-promotion__body__qr__text">
              <b>LINEの友達追加のQR画像</b>
            （友達追加のリンクは<a href="https://lin.ee/8DRGhCs" target="_blank" class="js-h2_line_text_second">こちら</a>）
            </p>
            <img alt="友達追加QR画像" rel="nofollow opener" class="p-promotion__body__qr__image" src="https://qr-official.line.me/sid/M/665wmzdn.png">
          </div>
          <p>
            <small>※ LINEの友達追加後、10秒で終わる簡単なアンケートのご協力をお願いする場合があります。</small>
          </p>
        </div>
      </div>
      `

      const adsenseHTML = location.host == 'pikawaka.com' ? `
        <ins class="adsbygoogle" data-ad-client="ca-pub-5447545650906273" data-ad-format="horizontal" data-ad-slot="4848620315" data-full-width-responsive="false" style="display:block; width: 100%; margin: 0 auto; overflow: hidden;"></ins>
        <script>(adsbygoogle = window.adsbygoogle || []).push({})</script>
      ` : ''

      const node = document.createElement( 'div' )
      node.innerHTML = questionnaireHTML + adsenseHTML
      h2.parentElement.insertBefore(node, h2)
    }

    if (!!$(".js-header-link").length) {
      document.querySelectorAll(".js-header-link").forEach((el) => {
        el.addEventListener("click", (e) => {
          e.preventDefault();
          const nextPath = $(e.currentTarget)
            .find("a")
            .attr("href");
          const copyIdPath = location.origin + location.pathname + nextPath;
          const visibleTextArea = document.createElement("textarea");
          visibleTextArea.className = "js-will-delete";
          visibleTextArea.textContent = copyIdPath;
          visibleTextArea.style.position = "fixed";
          visibleTextArea.style.left = "-100%";
          document.body.appendChild(visibleTextArea);
          visibleTextArea.select();
          document.execCommand("copy");
          $(e.currentTarget)
            .find(".notice-balloon-type-copy")
            .fadeIn("fast")
            .delay(1000)
            .fadeOut();
          $(".js-will-delete").remove();
        });
      });
    }

    // クリックした時にリロードされてしまうため、anchor linkの場合は preventDefault() する
    // ref: https://github.com/turbolinks/turbolinks/issues/75#issuecomment-244915109
    $(document).on("turbolinks:click", function (event) {
      if (event.target.getAttribute("href").charAt(0) === "#") {
        return event.preventDefault();
      }
    });
  });
})();
